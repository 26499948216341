import { AlertBanner } from "@atoms/index";
import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import { AccessCheck } from "@particles/index";
import formatDuration from "@utils/formatDuration";
import dayjs from "dayjs";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import PureComponentWrapper from "../../core/components/PureComponentWrapper";
import BasicSelectField from "../../core/fields/BasicSelectField";
import SwitchField from "../../core/fields/SwitchField";
import JobSearchOrCreateSelect, { jobToJobOption } from "../../jobs/inputs/JobSearchOrCreateSelect";
import PriceBookItemSearchOrCreateSelect, { priceBookItemToPriceBookItemOption } from "../../pricebook/inputs/PriceBookItemSearchOrCreateSelect";
import HorizontalLayout from "../components/HorizontalLayout";
import BasicDisplayField from "../fields/BasicDisplayField";
import CharField from "../fields/CharField";
import DateField from "../fields/DateField";
import PriceField from "../fields/PriceField";
import QuantityField from "../fields/QuantityField";
import SearchCreateSelectField from "../fields/SearchCreateSelectField";
import TextField from "../fields/TextField";
import { LineItemUnitTypes, PriceBookItemTypes } from "../utils/enums";
import { getIsTaxable, valueIsDefined } from "../utils/utils";

const LINE_ITEM_TYPE_MODE_MAP = {
    "ADD_SERVICE_CHARGE": PriceBookItemTypes.service,
    "EDIT_SERVICE_CHARGE": PriceBookItemTypes.service,
    "ADD_PART": PriceBookItemTypes.part,
    "EDIT_PART": PriceBookItemTypes.part,
    "ADD_OTHER_CHARGE": PriceBookItemTypes.other,
    "EDIT_OTHER_CHARGE": PriceBookItemTypes.other,
    "ADD_DISCOUNT": PriceBookItemTypes.discount,
    "EDIT_DISCOUNT": PriceBookItemTypes.discount,
}

const SUMMARY_PLACEHOLDERS = {
    [PriceBookItemTypes.service]: "Summary specific to this Service Charge. e.g. 'Replaced all filters.' or 'Cleaned evaporator coils/condensers.'",
    [PriceBookItemTypes.part]: "Summary specific to this Part or Material. e.g. 'R134 Freon.' or 'Turbo Air - 30200R0100 - Air Compressor.' ",
    [PriceBookItemTypes.other]: "Summary specific to this Misc. Charge. e.g. '30+ mile trip/gas charge' or 'Standard diagnostic fee for new customers.'",
    [PriceBookItemTypes.discount]: "Summary specific to this Discount. e.g. 'Thank you for your service, we hope you enjoy this Veteran's Discount.'",
}


class LineItemForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedPriceBookItem: undefined,
            selectedJobs: undefined,
            selectedSubtype: undefined,
            subtypeChoices: [],
            inventoryLevels: null,
            lastPurchasedDate: undefined,
        }
    }

    componentDidMount = async () => {
        const { lineItem, onFormDataChange } = this.props

        let isInventory = false
        let partID = null

        // Fetch subtype choices based on the line item type
        if (lineItem.line_item_type) {
            await this.fetchSubtypeChoices(lineItem.line_item_type);
        }

        if (valueIsDefined(lineItem.description)) {
            // Attempt to find a matching item in the PriceBook
            let params = new URLSearchParams()
            params.append("confirmed", true)
            params.append("description", lineItem.description)

            const priceBookEndpoint = DjangoUrls["pricebook:api-pricebookitem-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG) + `?${params}`
            const priceBookResponse = await fetch(priceBookEndpoint)
            const priceBookResponseJSON = await priceBookResponse.json()

            if (priceBookResponseJSON.results.length !== 0) {
                const priceBookItem = priceBookResponseJSON.results[0]

                isInventory = this.itemIsInventoryPart(priceBookItem)
                partID = priceBookItem.id

                this.setState({selectedPriceBookItem: priceBookItem})

                // If no subtype is set in the line item, use the subtype from the pricebook item (if available)
                if (valueIsDefined(priceBookItem.subtype) && !valueIsDefined(lineItem.subtype)) {
                    const selectedSubtype = this.state.subtypeChoices.find(subtype => subtype.value === priceBookItem.subtype);
                    onFormDataChange("subtype", selectedSubtype.value);
                    onFormDataChange("subtype_label", selectedSubtype.label);
                    this.setState({ selectedSubtype });
                }
            }
            else {
                this.setState({selectedPriceBookItem: null})
            }
        }
        else {
            this.setState({selectedPriceBookItem: null})
        }

        const hasJobsEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_jobs_enabled;
        if (hasJobsEntitlement && valueIsDefined(lineItem.jobs) && lineItem.jobs.length !== 0) {
            const endpoint = DjangoUrls["jobs:api-jobs-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG)
            const searchParams = new URLSearchParams()
            searchParams.set("id", lineItem.jobs.join(","))

            let selectedJobs = []

            let selectedJobsEndpoint
            let selectedJobsResponse
            let selectedJobsResponseJSON = {
                next: endpoint + "?" + searchParams.toString(),
                previous: null,
                results: [],
            }

            while (selectedJobsResponseJSON.next !== null) {
                selectedJobsEndpoint = selectedJobsResponseJSON.next
                selectedJobsResponse = await fetch(selectedJobsEndpoint)
                selectedJobsResponseJSON = await selectedJobsResponse.json()
                selectedJobs.push(...selectedJobsResponseJSON.results)
            }

            this.setState({selectedJobs: selectedJobs})
        }
        else {
            this.setState({selectedJobs: []})
        }

        if (isInventory && partID) {
            this.fetchInventoryLevels(partID)
        }

        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    componentDidUpdate(prevProps) {
        // If line_item_type changes, fetch new subtype choices
        if (this.props.lineItem.line_item_type !== prevProps.lineItem.line_item_type) {
            if (this.props.lineItem.line_item_type) {
                this.fetchSubtypeChoices(this.props.lineItem.line_item_type);
            } else {
                this.setState({ subtypeChoices: [] });
            }
        }
    }

    itemIsInventoryPart = (priceBookItem) => {
        return valueIsDefined(priceBookItem) && priceBookItem.pricebook_item_type === PriceBookItemTypes.part && priceBookItem.track_inventory === true
    }

    fetchInventoryLevels = async (partID) => {
        const inventoryLevelsEndpoint = DjangoUrls["inventory:api-inventory-levels-list"](window.MARKETPLACE_ENTITY_SLUG, partID)
        const inventoryLevelsResponse = await fetch(inventoryLevelsEndpoint)
        const inventoryLevelsResponseJSON = await inventoryLevelsResponse.json()

        this.setState({inventoryLevels: inventoryLevelsResponseJSON})
    }

    fetchLastPurchasedDate = async (partID, vendorID) => {
        const lastPurchasedDateEndpoint = DjangoUrls["pricebook:api-pricebookitem-last-purchased-detail"](window.MARKETPLACE_ENTITY_SLUG, vendorID, partID)
        const lastPurchasedDateResponse = await fetch(lastPurchasedDateEndpoint)
        const lastPurchasedDateResponseJSON = await lastPurchasedDateResponse.json()

        this.setState({lastPurchasedDate: lastPurchasedDateResponseJSON.last_purchased})
    }

    fetchSubtypeChoices = async (lineItemType) => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-subtypes-list"](window.MARKETPLACE_ENTITY_SLUG) + `?pricebook_item_type=${lineItemType}`;
        try {
            const response = await fetch(endpoint);
            const responseJSON = await response.json();
            const subtypeChoices = responseJSON.map(subtype => ({
                value: subtype.id,
                label: subtype.name,
            }));
            this.setState({ subtypeChoices });

            const { lineItem, onFormDataChange } = this.props;

            if (valueIsDefined(lineItem.subtype)) {
                const selectedSubtype = subtypeChoices.find(subtype => subtype.value === lineItem.subtype);
                if (selectedSubtype) {
                    onFormDataChange("subtype", selectedSubtype.value);
                    onFormDataChange("subtype_label", selectedSubtype.label);
                    this.setState({ selectedSubtype });
                }
            }
        } catch (error) {
            console.error("Error fetching subtype choices:", error);
            this.setState({ subtypeChoices: [] });
        }
    };

    shouldShowVendorCostWarning = () => {
        if (valueIsDefined(this.state.lastPurchasedDate)) {
            const lastPurchasedDate = dayjs(this.state.lastPurchasedDate)
            const oneMonthAgo = dayjs().subtract(1, "month")

            return lastPurchasedDate.isBefore(oneMonthAgo)
        }
        else {
            return false
        }
    }

    getFormCaption = (mode, objectName) => {
        const FORM_MODE_CAPTIONS = {
            ADD_SERVICE_CHARGE: `Please provide the following information to add a new service charge to this ${objectName.toLowerCase()}:`,
            EDIT_SERVICE_CHARGE: "Use the fields below to edit this service charge",
            ADD_PART: `Please provide the following information to add a new part or material to this ${objectName.toLowerCase()}:`,
            EDIT_PART: "Use the fields below to edit this part or material:",
            ADD_OTHER_CHARGE: `Please provide the following information to add a new miscellaneous charge (trip charge, diagnostic fee, etc.) to this ${objectName.toLowerCase()}`,
            EDIT_OTHER_CHARGE: "Use the fields below to edit this miscellaneous charge",
            ADD_DISCOUNT: `Please provide the following information to add a discount to this ${objectName.toLowerCase()}:`,
            EDIT_DISCOUNT: "Use the fields below to edit this discount:",
        }

        return FORM_MODE_CAPTIONS[mode]
    }

    getQuickBooksItemOptions = () => {
        const { mode } = this.props
        const itemType = LINE_ITEM_TYPE_MODE_MAP[mode]

        return window.QUICKBOOKS_DESKTOP_ITEMS.filter(item => item.item_type === itemType || item.item_type === null)
    }

    shouldShowInventoryDepletionWarning = () => {
        const {
            parent,
            lineItem,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isPurchaseOrderLineItem=false,
        } = this.props

        let initialQuantity = 0
        let initialInventoryLevel = null
        let inventoryLevel = null

        if (valueIsDefined(parent.line_items) && parent.line_items.length !== 0) {
            const initialItem = parent.line_items.find(part => part.description === lineItem.description)
            if (valueIsDefined(initialItem)) {
                initialQuantity = parseFloat(initialItem.quantity)

                if (valueIsDefined(initialItem.inventory_location) && valueIsDefined(this.state.inventoryLevels)) {
                    initialInventoryLevel = this.state.inventoryLevels.find(level => level.location === parseInt(initialItem.inventory_location))
                }
            }
        }

        if (valueIsDefined(lineItem.inventory_location) && valueIsDefined(this.state.inventoryLevels)) {
            inventoryLevel = this.state.inventoryLevels.find(level => level.location === parseInt(lineItem.inventory_location))
        }

        return (
            lineItem.line_item_type === PriceBookItemTypes.part &&  // This is a part
            lineItem.quantity > 0 &&  // A quantity is specified
            this.itemIsInventoryPart(this.state.selectedPriceBookItem) &&  // This is an inventory part
            inventoryLevel !== null &&  // We have inventory level data
            ((isJobLineItem && !!!parent.is_job_walk) || isInvoiceLineItem) &&  // This is either a service job or an invoice
            (parseFloat(lineItem.quantity) > initialQuantity || (initialInventoryLevel?.id !== inventoryLevel.id)) && // The quantity was increased since during edit, or the location changed
            (
                (initialInventoryLevel?.id === inventoryLevel.id)
                ?
                (parseFloat(inventoryLevel.quantity_on_hand) - (parseFloat(lineItem.quantity) - initialQuantity)) < 0  // Increasing the initial quantity would bring quantity on hand below 0
                :
                (parseFloat(inventoryLevel.quantity_on_hand) - parseFloat(lineItem.quantity)) < 0  // The new quantity would bring quantity on hand below 0
            )
        )
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            parent,
            lineItem,
            currencySymbol,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            returnScroll,
            objectName,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isPurchaseOrderLineItem=false,
            showQuickBooksItemSelect=false,
            useTaxes=false,
            pricebookDefaultTaxableService=false,
            pricebookDefaultTaxablePart=false,
            pricebookDefaultTaxableOther=false,
            estimatedDuration=null,
            totalTimeLogged=null,
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                if (mode.startsWith("ADD_")) {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                {lineItem.description && <UniversalButton type="primary" text="Add" handler={event => requestAction(mode)} />}
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            {
                                lineItem.description && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="primary" text="Save" handler={event => requestAction(mode)} />
                                    </ButtonGroupRow>
                                )
                            }
                            <ButtonGroupRow>
                                {lineItem.description && <UniversalButton type="danger" text="Delete" handler={event => requestAction("DELETE_" + mode.replace("ADD_", "").replace("EDIT_", ""))} />}
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    handleSelectionChange = (selectedOption) => {
        const {
            mode,
            submitting,
            parent,
            lineItem,
            priceBookClientOverrides=[],
            currencySymbol,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            returnScroll,
            objectName,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isPurchaseOrderLineItem=false,
            showQuickBooksItemSelect=false,
            useTaxes=false,
            pricebookDefaultTaxableService=false,
            pricebookDefaultTaxablePart=false,
            pricebookDefaultTaxableOther=false,
            estimatedDuration=null,
            totalTimeLogged=null,
        } = this.props

        if (selectedOption !== null) {
            if (!selectedOption.hasOwnProperty("object")) {
                onFormDataChange("description", selectedOption.value || null)

                this.setState({inventoryLevels: null, lastPurchasedDate: undefined, selectedPriceBookItem: null, selectedSubtype: null})
            }
            else {
                const priceBookItem = selectedOption.object
                const matchingOverride = priceBookClientOverrides.find((override) => override.pricebook_item.id === priceBookItem.id)

                if (priceBookItem.description !== lineItem.description) {
                    onFormDataChange("description", priceBookItem.description)
                    onFormDataChange("unit_type", priceBookItem.default_unit_type)
                    onFormDataChange("cost", parseFloat(priceBookItem.cost))
                    onFormDataChange("price", matchingOverride? parseFloat(matchingOverride.price) : parseFloat(priceBookItem.default_price))
                    onFormDataChange("summary", priceBookItem.summary_template)
                    onFormDataChange("is_taxable", priceBookItem.default_is_taxable)
                    onFormDataChange("quickbooks_desktop_item_id", priceBookItem.quickbooks_desktop_id)

                    // Set default subtype if available
                    if (valueIsDefined(priceBookItem.subtype?.id)) {
                        const selectedSubtype = this.state.subtypeChoices.find(subtype => subtype.value === priceBookItem.subtype.id);
                        if (selectedSubtype) {
                            onFormDataChange("subtype", selectedSubtype.value);
                            onFormDataChange("subtype_label", selectedSubtype.label);
                            this.setState({ selectedSubtype });
                        }
                    } else {
                        onFormDataChange("subtype", null)
                        onFormDataChange("subtype_label", "")
                        this.setState({selectedSubtype: null})
                    }

                    if (priceBookItem.pricebook_item_type === PriceBookItemTypes.part && isPurchaseOrderLineItem && valueIsDefined(parent.vendor)) {
                        const vendorPartCost = priceBookItem.vendor_part_costs.find(vendorCost => vendorCost.vendor === parent.vendor)

                        if (vendorPartCost) {
                            onFormDataChange("expected_cost", vendorPartCost.vendor_cost)
                            onFormDataChange("part_number", vendorPartCost.vendor_part_number)
                        }
                        else {
                            onFormDataChange("expected_cost", "")
                            onFormDataChange("part_number", "")
                        }
                    }

                    if (this.itemIsInventoryPart(priceBookItem)) {
                        this.fetchInventoryLevels(priceBookItem.id)
                    }

                    if (isPurchaseOrderLineItem && valueIsDefined(parent.vendor)) {
                        this.setState({lastPurchasedDate: undefined})
                        const hasPurchaseOrdersEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_purchase_orders_enabled;
                        if (hasPurchaseOrdersEntitlement) {
                            this.fetchLastPurchasedDate(priceBookItem.id, parent.vendor)
                        }
                    }

                    if (!priceBookItem.track_inventory) {
                        onFormDataChange("inventory_location", null)
                        onFormDataChange("inventory_location_label", "")
                    }
                }

                this.setState({selectedPriceBookItem: priceBookItem})
            }
        }
        else {
            onFormDataChange("description", "")
            onFormDataChange("unit_type", "")
            onFormDataChange("quantity", "")
            onFormDataChange("cost", "")
            onFormDataChange("price", "")
            onFormDataChange("summary", "")
            onFormDataChange("is_taxable", "")
            onFormDataChange("quickbooks_desktop_item_id", "")
            onFormDataChange("expected_cost", "")
            onFormDataChange("part_number", "")
            onFormDataChange("subtype", null)

            this.setState({inventoryLevels: null, lastPurchasedDate: undefined, selectedPriceBookItem: null, selectedSubtype: null})
        }
    }

    render() {
        const {
            mode,
            submitting,
            parent,
            lineItem,
            priceBookClientOverrides=[],
            formatCurrencyValue,
            currencySymbol,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            returnScroll,
            objectName,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isPurchaseOrderLineItem=false,
            isBillLineItem=false,
            showQuickBooksItemSelect=false,
            useTaxes=false,
            pricebookDefaultTaxableService=false,
            pricebookDefaultTaxablePart=false,
            pricebookDefaultTaxableOther=false,
            estimatedDuration=null,
            totalTimeLogged=null,
        } = this.props

        const matchingOverride = priceBookClientOverrides.find((override) => override.pricebook_item.id === this.state.selectedPriceBookItem?.id)
        const hasJobsEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_jobs_enabled;

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Line Item Create/Update">
                    <div className="data-panel__form" aria-label="Line Item Create/Update Form">
                        <p className="data-panel__form__caption">{this.getFormCaption(mode, objectName)}</p>
                            <SearchCreateSelectField
                                fieldName="description"
                                fieldLabel="Short Description"
                                fieldValue={lineItem.description || null}
                                inputComponent={
                                    this.state.selectedPriceBookItem === undefined
                                    ?
                                    <Spinner />
                                    :
                                    <PureComponentWrapper
                                        wrappedComponent={PriceBookItemSearchOrCreateSelect}
                                        updateTrigger={(currentProps, nextProps) => nextProps.defaultSelected?.value !== currentProps.defaultSelected?.value}
                                        onSelectionChange={selectedOption => this.handleSelectionChange(selectedOption)}
                                        defaultSelected={this.state.selectedPriceBookItem ? priceBookItemToPriceBookItemOption(this.state.selectedPriceBookItem) : (lineItem.description ? {value: null, label: lineItem.description} : null)}
                                        pricebookItemType={LINE_ITEM_TYPE_MODE_MAP[mode]}
                                        showCreateButton={false}
                                        creatable={!isPurchaseOrderLineItem && window.CURRENT_USER?.permissions.pricebook_create_permission >= PERMISSION_LEVEL.RESTRICTED}
                                        disabled={
                                            window.CURRENT_USER?.permissions.pricebook_list_permission < PERMISSION_LEVEL.RESTRICTED ||
                                            window.CURRENT_USER?.permissions.pricebook_view_permission < PERMISSION_LEVEL.FULL
                                        }
                                    ></PureComponentWrapper>
                                }
                                showButton={false}
                                errors={errors}
                            ></SearchCreateSelectField>
                            {window.CURRENT_USER?.permissions.pricebook_view_permission >= PERMISSION_LEVEL.FULL &&
                                lineItem.description && this.state.lastPurchasedDate === null
                                ?
                                    <Banner type="warning" text="The selected part has not been purchased from this vendor before. We recommend checking the vendor cost to confirm." extraMargin={true} />
                                :
                                this.shouldShowVendorCostWarning() && (
                                    <AccessCheck entitlements={["entitlement_purchase_orders_enabled"]}>
                                        <Banner type="warning" text="The selected part was last purchased from this vendor over a month ago. We recommend checking the vendor cost to confirm." extraMargin={true} />
                                    </AccessCheck>
                                )
                            }
                            {
                                    // Only show subtype if the line item type is part, service, or other charge and there are subtypes available
                                    lineItem.description && (isJobLineItem || isInvoiceLineItem || isEstimateLineItem) && this.state.subtypeChoices.length > 0 && (
                                        <BasicSelectField
                                            fieldName="subtype"
                                            fieldLabel="Subtype"
                                            fieldValue={lineItem.subtype || ""}
                                            fieldOnChange={subtype => {
                                                onFormDataChange("subtype", subtype || "")

                                                if (subtype) {
                                                    onFormDataChange("subtype_label", this.state.subtypeChoices.find(location => location.value === parseInt(subtype)).label)
                                                }
                                            }}
                                            choices={[
                                                { value: "", label: "Choose a subtype..." },
                                                ...this.state.subtypeChoices
                                            ]}
                                            optional={true}
                                            errors={errors}
                                        ></BasicSelectField>
                                    )
                                }
                        {
                            lineItem.description && <Fragment key={lineItem.description}>
                                {
                                    mode.includes("_SERVICE") && !isEstimateLineItem && (isJobLineItem ? !parent.is_job_walk : true) && !isBillLineItem && (
                                        <Fragment>
                                             <HorizontalLayout>
                                                <DateField
                                                    fieldName="service_start_date"
                                                    fieldLabel="Start Date"
                                                    fieldValue={lineItem.service_start_date}
                                                    fieldOnChange={service_start_date => onFormDataChange("service_start_date", service_start_date)}
                                                    errors={errors}
                                                ></DateField>
                                                <DateField
                                                    fieldName="service_end_date"
                                                    fieldLabel="End Date"
                                                    fieldValue={lineItem.service_end_date}
                                                    fieldOnChange={service_end_date => onFormDataChange("service_end_date", service_end_date)}
                                                    errors={errors}
                                                ></DateField>
                                            </HorizontalLayout>
                                        </Fragment>
                                    )
                                }
                                {
                                    mode.includes("_SERVICE") && (
                                        <SwitchField
                                            key={`unit_type_${this.state.selectedPriceBookItem?.id}`}
                                            fieldName="unit_type"
                                            fieldLabel="Unit Type"
                                            fieldValue={lineItem.unit_type !== undefined ? lineItem.unit_type === LineItemUnitTypes.flat_rate : false}
                                            fieldOnChange={unit_type => onFormDataChange("unit_type", unit_type ? LineItemUnitTypes.flat_rate : LineItemUnitTypes.hourly)}
                                            uncheckedText="Hourly"
                                            checkedText="Flat Rate"
                                            errors={errors}
                                        ></SwitchField>
                                    )
                                }
                                {
                                    mode.includes("_SERVICE") && lineItem.unit_type !== LineItemUnitTypes.flat_rate && (
                                        <HorizontalLayout>
                                            {
                                                estimatedDuration !== null && (
                                                    <BasicDisplayField
                                                        fieldName="estimated_duration"
                                                        fieldLabel="Est. Job Duration"
                                                        fieldValue={formatDuration(estimatedDuration)}
                                                    ></BasicDisplayField>
                                                )
                                            }
                                            {
                                                totalTimeLogged !== null && (
                                                    <BasicDisplayField
                                                        fieldName="total_time_logged"
                                                        fieldLabel="Total Time Logged"
                                                        fieldValue={formatDuration(totalTimeLogged)}
                                                    ></BasicDisplayField>
                                                )
                                            }
                                        </HorizontalLayout>
                                    )
                                }
                                {
                                    (!mode.includes("_SERVICE") || lineItem.unit_type !== LineItemUnitTypes.flat_rate) ?
                                    (
                                        <Fragment>
                                            {
                                                lineItem.line_item_type === PriceBookItemTypes.part && (isJobLineItem || isInvoiceLineItem || isPurchaseOrderLineItem) && this.itemIsInventoryPart(this.state.selectedPriceBookItem) && (
                                                    <BasicSelectField
                                                        fieldName="inventory_location"
                                                        fieldLabel="Inventory Location"
                                                        fieldValue={lineItem.inventory_location || ""}
                                                        fieldOnChange={inventory_location => {
                                                            onFormDataChange("inventory_location", inventory_location || "")

                                                            if (inventory_location) {
                                                                onFormDataChange("inventory_location_label", window.INVENTORY_LOCATION_CHOICES.find(location => location.value === parseInt(inventory_location)).label)
                                                            }
                                                        }}
                                                        choices={window.INVENTORY_LOCATION_CHOICES}
                                                        errors={errors}
                                                    ></BasicSelectField>
                                                )
                                            }
                                            {
                                                isPurchaseOrderLineItem
                                                ?
                                                    <Fragment>
                                                        <HorizontalLayout>
                                                            <QuantityField
                                                                fieldName="quantity"
                                                                fieldLabel="Quantity"
                                                                fieldValue={lineItem.quantity || ""}
                                                                fieldOnChange={quantity => onFormDataChange("quantity", quantity || "")}
                                                                errors={errors}
                                                            ></QuantityField>
                                                            {
                                                                <PriceField
                                                                    key={`expected_cost_${this.state.selectedPriceBookItem?.id}`}
                                                                    fieldName="expected_cost"
                                                                    fieldLabel="Expected Vendor Cost"
                                                                    fieldValue={lineItem.expected_cost}
                                                                    fieldOnChange={expected_cost => onFormDataChange("expected_cost", expected_cost)}
                                                                    currencySymbol={currencySymbol}
                                                                    errors={errors}
                                                                ></PriceField>
                                                            }
                                                        </HorizontalLayout>
                                                        <CharField
                                                            key={`part_number_${this.state.selectedPriceBookItem?.id}`}
                                                            fieldName="part_number"
                                                            fieldLabel="Vendor Part Number"
                                                            fieldValue={lineItem.part_number || ""}
                                                            fieldOnChange={part_number => onFormDataChange("part_number", part_number || "")}
                                                            maxLength={100}
                                                            errors={errors}
                                                        ></CharField>
                                                    </Fragment>
                                                :
                                                    <>
                                                    {
                                                        (mode.includes("_PART") || mode.includes("_CHARGE")) && !(isJobLineItem && window.CURRENT_USER?.permissions.cannot_view_job_financials) && !isBillLineItem && (
                                                            <>
                                                                <PriceField
                                                                    fieldName="cost"
                                                                    fieldLabel={`${(lineItem.line_item_type === PriceBookItemTypes.service && lineItem.unit_type === LineItemUnitTypes.hourly) ? "Hourly Cost" : "Unit Cost"}`}
                                                                    fieldValue={lineItem.cost}
                                                                    fieldOnChange={cost => onFormDataChange("cost", cost)}
                                                                    currencySymbol={currencySymbol}
                                                                    optional={isJobLineItem}
                                                                    errors={errors}
                                                                ></PriceField>
                                                                {
                                                                    valueIsDefined(lineItem.cost) && valueIsDefined(this.state.selectedPriceBookItem?.cost) && parseFloat(lineItem.cost) !== parseFloat(this.state.selectedPriceBookItem?.cost) && (
                                                                        <>
                                                                            <AlertBanner type="info" size="md" title="The cost of this charge does not match its cost in the pricebook." subtitle={<span><a className="text-link text-link--inline" href={DjangoUrls["pricebook:pricebookitem-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedPriceBookItem?.id)} target="_blank">Click here</a> to update the pricebook.</span>} />
                                                                            <div style={{marginBottom: "1em"}}></div>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    <HorizontalLayout>
                                                        <QuantityField
                                                            fieldName="quantity"
                                                            fieldLabel={<Fragment>{(mode.includes("_SERVICE_CHARGE") && (lineItem.unit_type === LineItemUnitTypes.hourly || lineItem.unit_type === undefined)) ? "Hours" : "Quantity"}{isJobLineItem ? <Fragment><br /><br /></Fragment> : ""}</Fragment>}
                                                            fieldValue={lineItem.quantity || ""}
                                                            fieldOnChange={quantity => onFormDataChange("quantity", quantity || "")}
                                                            errors={errors}
                                                        ></QuantityField>
                                                        {
                                                            !(isJobLineItem && window.CURRENT_USER?.permissions.cannot_view_job_financials) && (
                                                                <PriceField
                                                                    key={`price_${this.state.selectedPriceBookItem?.id}`}
                                                                    fieldName="price"
                                                                    fieldLabel={<Fragment>{(mode.includes("_SERVICE_CHARGE") && (lineItem.unit_type === LineItemUnitTypes.hourly || lineItem.unit_type === undefined)) ? "Hourly Rate" : "Unit Price"}{isJobLineItem ? <br /> : ""}</Fragment>}
                                                                    fieldValue={lineItem.price}
                                                                    fieldOnChange={price => onFormDataChange("price", price)}
                                                                    currencySymbol={currencySymbol}
                                                                    credit={mode.includes("_DISCOUNT")}
                                                                    errors={errors}
                                                                    optional={isJobLineItem}
                                                                ></PriceField>
                                                            )
                                                        }
                                                    </HorizontalLayout>
                                                    {
                                                        !(isJobLineItem && window.CURRENT_USER?.permissions.cannot_view_job_financials) && valueIsDefined(matchingOverride) && window.CURRENT_USER?.permissions.pricebook_view_permission >= PERMISSION_LEVEL.FULL && (
                                                            <>
                                                                <AlertBanner type="info" size="md" title="Special price for this client:" subtitle={<span>{formatCurrencyValue(lineItem.line_item_type === PriceBookItemTypes.discount ? matchingOverride.pricebook_item.default_price * -1 : matchingOverride.pricebook_item.default_price)}{" -> "}{formatCurrencyValue(lineItem.line_item_type === PriceBookItemTypes.discount ? matchingOverride.price * -1 : matchingOverride.price)}</span>} />
                                                                <div style={{marginBottom: "1em"}}></div>
                                                            </>
                                                        )
                                                    }
                                                    </>
                                            }
                                            {
                                                this.shouldShowInventoryDepletionWarning() && (
                                                    <Banner type="warning" text="Using this quantity for this part would cause its inventory level to drop below 0. Please confirm you've selected the correct part." />
                                                )
                                            }
                                        </Fragment>
                                    )
                                    :
                                    !(isJobLineItem && window.CURRENT_USER?.permissions.cannot_view_job_financials) && (
                                            <Fragment>
                                                {
                                                    (mode.includes("_PART") || mode.includes("_CHARGE")) && !isBillLineItem && (
                                                        <>
                                                            <PriceField
                                                                fieldName="cost"
                                                                fieldLabel={`${(lineItem.line_item_type === PriceBookItemTypes.service && lineItem.unit_type === LineItemUnitTypes.hourly) ? "Hourly Cost" : "Unit Cost"}`}
                                                                fieldValue={lineItem.cost}
                                                                fieldOnChange={cost => onFormDataChange("cost", cost)}
                                                                currencySymbol={currencySymbol}
                                                                optional={isJobLineItem}
                                                                errors={errors}
                                                            ></PriceField>
                                                            {
                                                                valueIsDefined(lineItem.cost) && valueIsDefined(this.state.selectedPriceBookItem?.cost) && parseFloat(lineItem.cost) !== parseFloat(this.state.selectedPriceBookItem?.cost) && (
                                                                    <>
                                                                        <AlertBanner type="info" size="md" title="The cost of this charge does not match its cost in the pricebook." subtitle={<span><a className="text-link text-link--inline" href={DjangoUrls["pricebook:pricebookitem-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedPriceBookItem?.id)} target="_blank">Click here</a> to update the pricebook.</span>} />
                                                                        <div style={{marginBottom: "1em"}}></div>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }

                                                <PriceField
                                                    key={`price_${this.state.selectedPriceBookItem?.id}`}
                                                    fieldName="price"
                                                    fieldLabel={<Fragment>{"Rate"}{isJobLineItem ? <br /> : ""}</Fragment>}
                                                    fieldValue={lineItem.price}
                                                    fieldOnChange={price => onFormDataChange("price", price)}
                                                    currencySymbol={currencySymbol}
                                                    credit={mode.includes("_DISCOUNT")}
                                                    errors={errors}
                                                    optional={isJobLineItem}
                                                ></PriceField>
                                                {
                                                    valueIsDefined(matchingOverride) && window.CURRENT_USER?.permissions.pricebook_view_permission >= PERMISSION_LEVEL.FULL && (
                                                        <>
                                                            <AlertBanner type="info" size="md" title="Special price for this client:" subtitle={<span>{formatCurrencyValue(matchingOverride.pricebook_item.default_price)}{" -> "}{formatCurrencyValue(matchingOverride.price)}</span>} />
                                                            <div style={{marginBottom: "1em"}}></div>
                                                        </>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                }
                                {
                                    useTaxes && (isEstimateLineItem || isInvoiceLineItem) && !mode.includes("_DISCOUNT") && (
                                        <SwitchField
                                            key={`is_taxable_${this.state.selectedPriceBookItem?.id}`}
                                            fieldName="is_taxable"
                                            fieldLabel="Tax"
                                            fieldValue={valueIsDefined(lineItem.is_taxable) ? lineItem.is_taxable : getIsTaxable(lineItem, pricebookDefaultTaxableService, pricebookDefaultTaxablePart, pricebookDefaultTaxableOther)}
                                            fieldOnChange={is_taxable => onFormDataChange("is_taxable", is_taxable)}
                                            uncheckedText="Non-Taxable"
                                            checkedText="Taxable"
                                            errors={errors}
                                        ></SwitchField>
                                    )
                                }
                                <TextField
                                    key={`summary_${this.state.selectedPriceBookItem?.id}`}
                                    fieldName="summary"
                                    fieldLabel={`${mode.includes("_SERVICE_CHARGE") ? "Service" : "Detailed"} Summary`}
                                    fieldValue={lineItem.summary || ""}
                                    fieldOnChange={summary => onFormDataChange("summary", summary || "")}
                                    placeholder={SUMMARY_PLACEHOLDERS[LINE_ITEM_TYPE_MODE_MAP[mode]]}
                                    rows={3}
                                    optional={!(isJobLineItem && mode.includes("_SERVICE_CHARGE"))}
                                    errors={errors}
                                ></TextField>
                                {
                                    (isEstimateLineItem || isInvoiceLineItem) && showQuickBooksItemSelect && (
                                        <BasicSelectField
                                            key={`qb_select_${this.state.selectedPriceBookItem?.id}`}
                                            fieldName="quickbooks_desktop_item_id"
                                            fieldLabel="QuickBooks PriceBook Item"
                                            fieldValue={lineItem.quickbooks_desktop_item_id || ""}
                                            fieldOnChange={quickbooks_desktop_item_id => onFormDataChange("quickbooks_desktop_item_id", quickbooks_desktop_item_id || "")}
                                            choices={this.getQuickBooksItemOptions()}
                                            errors={errors}
                                            optional={isEstimateLineItem}
                                        ></BasicSelectField>
                                    )
                                }
                            </Fragment>
                        }
                            {
                                isPurchaseOrderLineItem && (
                                    <AccessCheck entitlements={["entitlement_jobs_enabled"]}>
                                        <SearchCreateSelectField
                                            fieldName="jobs"
                                            fieldLabel="Associated Jobs"
                                            fieldValue={lineItem.jobs || []}
                                            inputComponent={
                                                this.state.selectedJobs === undefined
                                                ?
                                                <Spinner />
                                                :
                                                <JobSearchOrCreateSelect
                                                    isMulti={true}
                                                    refetchSelection={false}
                                                    onSelectionChange={selectedOptions => {
                                                        onFormDataChange("jobs", (selectedOptions || []).filter(option => option !== null).map(option => option.value))
                                                        onFormDataChange("jobs_label", (selectedOptions || []).filter(option => option !== null).map(option => option.object.custom_id || option.object.id).join(", "))
                                                        this.setState({selectedJobs: (selectedOptions || [])})
                                                    }}
                                                    defaultSelected={this.state.selectedJobs.map(job => jobToJobOption(job, this.props.preferredTimezone))}
                                                    showCreateButton={false}
                                                    preferredTimezone={this.props.preferredTimezone}
                                                ></JobSearchOrCreateSelect>
                                            }
                                            showButton={false}
                                            optional={true}
                                            errors={errors}
                                        ></SearchCreateSelectField>
                                    </AccessCheck>
                                )
                            }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default LineItemForm;
